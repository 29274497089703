// components/BlogPost.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styles from './BlogPost.module.css';

const BlogPost = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    axios
      .get(`https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/article?slug=${slug}&_embed&context=view`)
      .then((response) => {
        if (response.data.length > 0) {
          setArticle(response.data[0]);
        } else {
          console.error('Artykuł nie został znaleziony');
        }
      })
      .catch((error) => {
        console.error('Błąd podczas pobierania artykułu:', error);
      });
  }, [slug]);

  if (!article) {
    return <div>Ładowanie...</div>;
  }

  return (
    <div className={styles.blogPost}>
        <a className={styles.backButtonAnchor} href='/blog'>
            <button className={styles.backButton}>Powrót do aktualności</button>
        </a>
          <div className={styles.thumbnail}>
            {article._embedded &&
              article._embedded['wp:featuredmedia'] &&
              article._embedded['wp:featuredmedia'][0] && (
                  <img
                  //   src={article._embedded['wp:featuredmedia'][0].source_url}
                  src={article._embedded['wp:featuredmedia'][0].source_url}
                  alt={article.title.rendered}
                  />
                )}
          </div>
    <div className={styles.photoAndContent}>
          <h1>{article.title.rendered}</h1>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: article.content.rendered }}
          ></div>
      </div>
    </div>
  );
};

export default BlogPost;
