import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const RoomDetail = () => {
    const { slug } = useParams(); // Pobieramy slug z URL
    const [room, setRoom] = useState(null);

    useEffect(() => {
        fetch(`https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/pokoje?slug=${slug}&acf_format=standard`)
            .then(response => response.json())
            .then(data => setRoom(data[0])) // Zakładamy, że API zwraca tablicę
            .catch(error => console.error('Error fetching room details:', error));
    }, [slug]);

    if (!room) {
        return <p>Ładowanie...</p>;
    }

    return (
        <div className="room-detail">
            {/* Wyświetlanie obrazu */}
            <img src={room.acf.image} alt={room.title.rendered} className="room-image w-full h-auto object-cover mb-4" />
            <h1 className="text-4xl font-bold">{room.title.rendered}</h1>
            <p className="text-lg">{room.acf.description}</p>
            <p className="text-lg">Maksymalna ilość osób: {room.acf.max_guests}</p>
            <p className="text-lg">Powierzchnia: {room.acf.area} m²</p>
        </div>
    );
};

export default RoomDetail;
