// SubpageLoadingContext.js
import React, { createContext, useState, useCallback } from 'react';

const SubpageLoadingContext = createContext();

export const SubpageLoadingProvider = ({ children }) => {
  const [loadingComponents, setLoadingComponents] = useState(new Set());

  const startLoading = useCallback((componentName) => {
    setLoadingComponents((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.add(componentName);
      return newSet;
    });
  }, []);

  const finishLoading = useCallback((componentName) => {
    setLoadingComponents((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(componentName);
      return newSet;
    });
  }, []);

  const isLoading = loadingComponents.size > 0;

  return (
    <SubpageLoadingContext.Provider value={{ startLoading, finishLoading, isLoading }}>
      {children}
    </SubpageLoadingContext.Provider>
  );
};

export default SubpageLoadingContext;
