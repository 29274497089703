import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaGoogle, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="mt-auto bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)] text-white py-8">
      <div className="container mx-auto px-4">
        {/* Sekcja kontaktowa */}
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-4 md:mb-0">
            <h2 className="text-xl font-bold">Nazwa Pensjonatu</h2>
            <p>ul. Przykładowa 123</p>
            <p>00-000 Miasto</p>
          </div>
          <div>
            <p>
              Email:{' '}
              <a href="mailto:kontakt@pensjonat.pl" className="underline">
                kontakt@pensjonat.pl
              </a>
            </p>
            <p>
              Telefon:{' '}
              <a href="tel:+48123456789" className="underline">
                +48 123 456 789
              </a>
            </p>
          </div>
        </div>

        {/* Mini-sekcja "Zobacz także" */}
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2">Zobacz także</h3>
          <div className="mt-1 mb-4 flex space-x-4">
            <a href="https://www.facebook.com/p/Pensjonat-Zdrojowy-w-Rabce-Zdroju-100054376756882/" className="text-white hover:text-gray-300">
              <FaFacebook size={24} />
            </a>
            <a href="https://www.instagram.com/komplekspensjonatzdrojowy" className="text-white hover:text-gray-300">
              <FaInstagram size={24} />
            </a>
            <a href="https://g.co/kgs/qeuDXsx" className="text-white hover:text-gray-300">
              <FaGoogle size={24} />
            </a>
          </div>
          <ul className="flex flex-wrap">
            <li className="mr-4 mb-2">
              <Link to="/" className="underline hover:text-gray-300">
                Strona główna
              </Link>
            </li>
            <li className="mr-4 mb-2">
              <Link to="/about" className="underline hover:text-gray-300">
                O nas
              </Link>
            </li>
            <li className="mr-4 mb-2">
              <Link to="/rooms" className="underline hover:text-gray-300">
                Pokoje
              </Link>
            </li>
            <li className="mr-4 mb-2">
              <Link to="/contact" className="underline hover:text-gray-300">
                Kontakt
              </Link>
            </li>
            {/* Dodaj kolejne linki zgodnie z elementami w navbarze */}
          </ul>
        </div>

        {/* Linki do RODO i Polityki Prywatności */}
        <div className="mt-8">
          <ul className="flex flex-wrap">
            <li className="mr-4 mb-2">
              <Link to="/rodo" className="underline hover:text-gray-300">
                RODO
              </Link>
            </li>
            <li className="mr-4 mb-2">
              <Link to="/privacy-policy" className="underline hover:text-gray-300">
                Polityka Prywatności
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
