import React, { useEffect, useState, useContext } from 'react';
import LoadingContext from '../contexts/LoadingContext';
import styles from './ZigZagSection.module.css';

const ZigzagSection = () => {
    const { startLoading, finishLoading } = useContext(LoadingContext);
    const [sections, setSections] = useState([]);

    useEffect(() => {
        startLoading('ZigZagSection');
        fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/zigzag-section?acf_format=standard')
            .then(response => response.json())
            .then(data => {
                const processedSections = data.map(section => ({
                    title: section.acf.title,
                    description: section.acf.description,
                    image: section.acf.image // Zmiana na section.acf.image
                }));
                setSections(processedSections.reverse()); // Odwracamy kolejność
                finishLoading('ZigZagSection');
            })
            .catch(error => console.error('Error fetching zigzag sections:', error));
            finishLoading('ZigZagSection');
    }, [startLoading, finishLoading]);

    return (
        <div className={styles.zigzagSection}>
            {sections.map((section, index) => (
                <div
                    key={index}
                    className={`${styles.zigzagItem} flex flex-col md:flex-row ${
                        index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                    } my-10`}
                >
                    {/* Text Section */}
                    <div className={`${styles.textContent} md:w-1/2 py-6 flex flex-col justify-center`}> {/* Dodajemy flex i justify-center */}
                        <h2 className="text-2xl font-bold mb-4">{section.title}</h2>
                        <p className="text-lg">{section.description}</p>
                    </div>

                    {/* Image Section */}
                    <div className={`relative ${styles.imageContent} md:w-1/2`}>
                        <img
                            src={section.image}
                            alt={section.title}
                            className="w-full h-auto object-cover rounded-lg shadow-lg"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ZigzagSection;
