// src/components/ImageGallery/ImageGallery.js

import React, { useEffect, useState } from 'react';
import styles from './ImageGallery.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ImageGallery = () => {
  const [sections, setSections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSections = async () => {
      try {
        // Definiujemy typy sekcji
        const types = ['pensjonat', 'kompleks', 'spa'];
        // Pobieramy wszystkie sekcje
        const response = await axios.get(`https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/gallery`);
        const allSections = response.data;

        // Filtrujemy sekcje na podstawie section_type
        const fetchedSections = allSections.filter(section => types.includes(section.acf.section_type));
        setSections(fetchedSections.reverse());
        console.log('Fetched Sections:', fetchedSections); // Do debugowania
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    };

    fetchSections();
  }, []);

  const handleSectionClick = (section_type) => {
    navigate(`/galeria/${section_type}`);
  };

  return (
    <div className={styles.galleryContainer}>
      {sections.length > 0 ? (
        sections.map((section) => (
          <div
            key={section.id}
            className={styles.galleryItem}
            style={{ backgroundImage: `url(${section.acf.image_1_url || 'https://test.itneed.pl/WORDPRESS/wp-content/uploads/2024/04/default-image.jpg'})`, backgroundSize: `cover` }}
            onClick={() => handleSectionClick(section.acf.section_type)}
          >
            <div className={styles.overlay}>
              <h2 className={styles.title}>{section.title.rendered}</h2>
            </div>
          </div>
        ))
      ) : (
        <p>Brak dostępnych sekcji.</p>
      )}
    </div>
  );
};

export default ImageGallery;
