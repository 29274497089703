import React, { useEffect, useState, useCallback, useRef } from 'react';
import styles from './PartnersCustomSlider.module.css';
import { FaHome } from "react-icons/fa";

const SlideContent = ({ slide, show }) => {
  useEffect(() => {
    // console.log(`SlideContent for slide ${slide.id} - show: ${show}`);
  }, [slide, show]);

  if (!slide) {
    return null;
  }

  // Extract data from the slide object
  const author = slide.acf?.slide_author || slide.author_name || 'No author';
  const title = slide.acf?.slide_title || slide.title?.rendered || 'No title';
  const topic = slide.acf?.slide_topic || 'No topic';
  const href = slide.acf?.slide_link || '/#';
  const description = slide.acf?.slide_description || slide.content?.rendered || 'No description';

  return (
    <div className={`${styles.content} ${show ? styles.show : styles.hide}`}>
      <div className={`${styles.contentItem} ${styles.author}`}>{author}</div>
      <div className={`${styles.contentItem} ${styles.title}`}>{title}</div>
      <div className={`${styles.contentItem} ${styles.topic}`}>{topic}</div>
      <div
        className={`${styles.contentItem} ${styles.des}`}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <div className={`${styles.contentItem} ${styles.buttons}`}>
        <button>
          <a href="/" className='text-white'>
            <FaHome />
          </a>
        </button>
        <button>
          <a href={href}>
            ODWIEDŹ STRONĘ
          </a>
        </button>
      </div>
    </div>
  );
};

const SliderComponent = () => {
  // State variables
  const [slides, setSlides] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [animatingThumbnail, setAnimatingThumbnail] = useState(null);
  const [thumbnailPosition, setThumbnailPosition] = useState({
    top: 0,
    left: 0,
    width: 150,
    height: 220,
    aspectRatio: 1, // Default aspect ratio
  });
  const [isExpanding, setIsExpanding] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showFullscreenContent, setShowFullscreenContent] = useState(false);
  const [isSlidingThumbnails, setIsSlidingThumbnails] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const [visibleThumbnailsCount, setVisibleThumbnailsCount] = useState(getVisibleThumbnailsCount());

  const thumbnailsContainerRef = useRef(null);
  const activeThumbnailRef = useRef(null);

  // Unique key counter
  const uniqueIdRef = useRef(0);

  // Function to get visible thumbnails count
  function getVisibleThumbnailsCount() {
    const width = window.innerWidth;
    if (width >= 1440) {
      return 4;
    } else if (width >= 1024) {
      return 3;
    } else if (width >= 768) {
      return 3;
    } else {
      return 2;
    }
  }

  useEffect(() => {
    function handleResize() {
      setVisibleThumbnailsCount(getVisibleThumbnailsCount());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch data from WordPress REST API
  useEffect(() => {
    fetch(
      'https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/partners?acf_format=standard'
    )
      .then((response) => response.json())
      .then((data) => {
        setSlides(data.reverse());
        setCurrentSlide(data[0]);

        // Initialize thumbnails
        const initialThumbnails = data.slice(1, visibleThumbnailsCount + 1).map(thumb => ({
          ...thumb,
          uniqueId: uniqueIdRef.current++,
        }));
        setThumbnails(initialThumbnails);

        setIsFirstRender(false);
        // Show content after initial render
        setTimeout(() => {
          setShowContent(true);
        }, 100); // Slight delay to allow CSS transition
      })
      .catch((error) => console.error('Error fetching slides:', error));
  }, [visibleThumbnailsCount]);

  // Update thumbnails when visibleThumbnailsCount changes
  useEffect(() => {
    if (slides.length > 0 && currentSlide) {
      const currentSlideIndex = slides.findIndex(slide => slide.id === currentSlide.id);
      const newThumbnails = [];
      for (let i = 1; i <= visibleThumbnailsCount; i++) {
        const index = (currentSlideIndex + i) % slides.length;
        newThumbnails.push({
          ...slides[index], 
          uniqueId: uniqueIdRef.current++,
        });
      }
      setThumbnails(newThumbnails);
    }
  }, [visibleThumbnailsCount, slides, currentSlide]);

  // Function to handle moving to the next slide
  const handleNext = useCallback(() => {
    if (isAnimating || thumbnails.length === 0) return;

    setIsAnimating(true);

    // Set the animating thumbnail and its position
    const nextThumbnail = thumbnails[0];

    if (activeThumbnailRef.current) {
      const rect = activeThumbnailRef.current.getBoundingClientRect();

      const aspectRatio = rect.height / rect.width;

      setThumbnailPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        aspectRatio: aspectRatio,
      });
    }

    setAnimatingThumbnail(nextThumbnail);

    // Start the expansion
    requestAnimationFrame(() => {
      setIsExpanding(true);
    });

    // Visually hide the expanding thumbnail in the thumbnails container
    setTimeout(() => {
      setThumbnails((prevThumbnails) =>
        prevThumbnails.map((thumb, index) =>
          index === 0 ? { ...thumb, isHidden: true } : thumb
        )
      );
    }, 0);

    // After the expansion animation completes
    setTimeout(() => {
      // Show the content over the fullscreen image
      setShowFullscreenContent(true);

      // Start sliding thumbnails
      setIsSlidingThumbnails(true);

      // After thumbnails have moved left
      setTimeout(() => {
        setThumbnails((prevThumbnails) => {
          const newThumbnails = prevThumbnails.slice(1);

          // Generate a unique key for the slide
          // const uniqueId = uniqueIdRef.current++;

          // Calculate the next thumbnail to add
          const currentSlideIndex = slides.findIndex(s => s.id === currentSlide.id);
          const nextIndex = (currentSlideIndex + visibleThumbnailsCount) % slides.length;
          const nextThumbnailToAdd = slides[nextIndex];

          // Add the next thumbnail to the end of the thumbnails array with a unique key
          return [...newThumbnails, { ...nextThumbnailToAdd, uniqueId: uniqueIdRef.current++ }];
        });

        

        // Update the current slide to the animating thumbnail
        setCurrentSlide(nextThumbnail);

        setIsSlidingThumbnails(false);


        // Start fading out the fullscreen image
        setIsFadingOut(true);

        // After fade-out transition completes
        setTimeout(() => {
          // Remove the fullscreenImage
          setAnimatingThumbnail(null);
          setIsFadingOut(false);
          setIsAnimating(false);
          // Remove the expand class
          setIsExpanding(false);

          // Hide the content over the fullscreen image
          setShowFullscreenContent(false);
        }, 1000); // Duration of the fade-out transition (1 second)
      }, 1000); // Duration of the thumbnails slide animation (1 second)
    }, 1000); // Duration of the expansion animation (1 second)
  }, [isAnimating, thumbnails, currentSlide, visibleThumbnailsCount, slides]);

  // Automatic slide change
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 10000); // Loop every 10 seconds

    return () => clearInterval(interval);
  }, [handleNext]);

  if (isFirstRender) {
    return null; // Don't render the component until data is loaded
  }

  return (
    <div className={styles.sliderComponent}>
      {/* <div className={styles.backToMainLogo}>
        <div className={`${styles.backToMainLogo} ${styles.backButton}`}>
          <button>STRONA GŁÓWNA</button>
        </div>
      </div> */}
      {/* Current Slide Background Image */}
      <div className={styles.currentSlide}>
        {/* Background Image */}
        <img
          src={currentSlide.acf?.slide_image || currentSlide.featured_media_url}
          alt={currentSlide.title?.rendered || 'Slide Image'}
          className={styles.backgroundImage}
        />

        {/* Content */}
        <SlideContent slide={currentSlide} show={showContent} />
      </div>

      {/* Fullscreen Expanding Image */}
      {animatingThumbnail && (
        <div
          className={`${styles.fullscreenImage} ${
            isExpanding ? styles.expand : ''
          } ${isFadingOut ? styles.fadeOut : ''}`}
          style={{
            '--thumbnail-top': `${thumbnailPosition.top}px`,
            '--thumbnail-left': `${thumbnailPosition.left}px`,
            '--thumbnail-width': `${thumbnailPosition.width}px`,
            '--thumbnail-height': `${thumbnailPosition.height}px`,
            '--aspect-ratio': `${thumbnailPosition.aspectRatio}`,
          }}
        >
          <img
            src={
              animatingThumbnail.acf?.slide_image ||
              animatingThumbnail.featured_media_url
            }
            alt={animatingThumbnail.title?.rendered || 'Slide Image'}
          />
          {/* Content over the fullscreen image */}
          <SlideContent
            slide={animatingThumbnail}
            show={showFullscreenContent}
          />
        </div>
      )}

      {/* Thumbnails */}
      <div className={styles.thumbnailsContainer}>
        <div
          className={`${styles.thumbnails} ${
            isSlidingThumbnails ? styles.sliding : ''
          }`}
          ref={thumbnailsContainerRef}
        >
          <div
            className={`${styles.thumbnailsInner} ${
              isSlidingThumbnails ? styles.thumbnailsInnerAnimation : styles.thumbnailsInnerNoAnimation
            }`}
          >
            {thumbnails.map((thumb, index) => (
              <div
                key={`unique-${thumb.uniqueId}`}
                className={`${styles.thumbnailItem} ${
                  thumb.isHidden ? styles.hidden : ''
                }`}
                ref={index === 0 ? activeThumbnailRef : null}
              >
                <img
                  src={
                    thumb.acf?.slide_thumbnail ||
                    thumb.acf?.slide_image ||
                    thumb.featured_media_url
                  }
                  alt={thumb.title?.rendered || 'Thumbnail'}
                />
                <div className={styles.thumbnailContent}>
                  <div className={styles.thumbnailTitle}>
                    {/* {thumb.acf?.slide_title || thumb.title?.rendered || 'No title'} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className={styles.arrows}>
        <button onClick={handleNext}>&gt;</button>
      </div>
    </div>
  );
};

export default SliderComponent;
