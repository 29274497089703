// NavbarAlt.js
import React, { useEffect, useState } from 'react';
import styles from './NavbarAlt.module.css';
import Logo from '../media/logo.png';
import WeatherWidget from './WeatherWidget';
import { FaFacebook, FaGoogle, FaInstagram, FaPhoneAlt } from 'react-icons/fa';
import { IoIosMail } from "react-icons/io";


const NavbarAlt = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  // Blokowanie przewijania strony, gdy menu jest otwarte
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Czyszczenie efektu przy odmontowaniu komponentu
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  // Obsługa klawisza Esc do zamykania menu
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        closeMenu();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ''}`}>
      <div className={styles.navContainer}>
        {/* Lewa część */}
        <div className={styles.navLeft}>
          <div className={`${styles.topLeft} ${scrolled ? styles.hidden : ''}`}>
            <a href="tel:+48604259509">
              <FaPhoneAlt size={24} />
            </a>
            <a href="mailto:recepcja@komplekspensjonatzdrojowy.pl">
              <IoIosMail size={32} />
            </a>
          </div>
          <div className={styles.bottomLeft}>
            {/* Linki po lewej stronie */}
            <ul>
              <li><a href="/cennik" className={styles.navLink}>Cennik</a></li>
              <li><a href="/galeria" className={styles.navLink}>Galeria</a></li>
              <li><a href="/atrakcje-okolicy" className={styles.navLink}>Atrakcje okolicy</a></li>
              <li><a href="/strefa-relaksu" className={styles.navLink}>Strefa Relaksu</a></li>
            </ul>
          </div>
        </div>

        {/* Logo */}
        <div className={styles.logoContainer}>
          <a href="/">
            <img src={Logo} alt="Logo" className={styles.logo} />
          </a>
        </div>

        {/* Prawa część */}
        <div className={styles.navRight}>
          <div className={`${styles.topRight} ${scrolled ? styles.hidden : ''}`}>
            {/* Ikony social media */}
            <a href="https://www.facebook.com/pensjonatzdrojowy/">
              <FaFacebook size={32} />
            </a>
            <a href="https://www.instagram.com/komplekspensjonatzdrojowy/">
              <FaInstagram size={32} />
            </a>
            <a href="https://g.co/kgs/dVGok31">
              <FaGoogle size={32} />
            </a>
            {/* Widget pogodowy */}
            <WeatherWidget />
          </div>
          <div className={styles.bottomRight}>
            {/* Linki po prawej stronie */}
            <ul>
              {/* <li><a href="/pokoje" className={styles.navLink}>Pokoje</a></li> */}
              <li><a href="/oferty-specjalne" className={styles.navLink}>Oferty Specjalne</a></li>
              <li><a href="/partnerzy" className={styles.navLink}>Partnerzy</a></li>
              <li><a href="/kontakt" className={styles.navLink}>Kontakt</a></li>
              <li><a href="/blog" className={styles.navLink}>Blog</a></li>
            </ul>
          </div>
        </div>
      </div>

      {/* Warstwa mobilna - na mniejszych ekranach */}
      <div className={styles.mobileLayer}>
        <a href="/">
          <img src={Logo} alt="Logo" className="h-[78px] mb-1" />
        </a>
        <button 
          onClick={toggleMenu} 
          className={styles.hamburgerButton} 
          aria-label="Toggle menu"
        >
          <svg className="h-[38px] w-[38px]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} 
            />
          </svg>
        </button>
      </div>

      {/* Overlay */}
      <div 
        className={`${styles.overlay} ${isOpen ? styles.overlayOpen : ''}`}
        onClick={closeMenu}
      />

      {/* Rozwijane menu */}
      <div 
        className={`${styles.menu} ${isOpen ? styles.menuOpen : ''}`}
        onClick={(e) => e.stopPropagation()} // Zapobiega zamknięciu menu przy kliknięciu wewnątrz
      >
      
        {/* Ikona "X" do zamknięcia menu */}
        <button 
          onClick={closeMenu} 
          className={styles.closeButton} 
          aria-label="Close menu"
        >
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        </button>

        {/* Lista nawigacyjna */}
        <nav className={styles.navLinks}>
          <div className={styles.weatherWidgetContainer}>
            <WeatherWidget />
          </div>
          <div className={styles.social_title}>
            <h3>Znajdź nas na:</h3>
            <div className={styles.social_icons}>
              <a href="https://www.facebook.com/p/Pensjonat-Zdrojowy-w-Rabce-Zdroju-100054376756882/" className="hover:text-gray-300">
                <FaFacebook size={52} />
              </a>
              <a href="https://www.instagram.com/komplekspensjonatzdrojowy" className=" hover:text-gray-300">
                <FaInstagram size={52} />
              </a>
              <a href="https://g.co/kgs/qeuDXsx" className=" hover:text-gray-300">
                <FaGoogle size={52} />
              </a>
            </div>
          </div>
          <a href="/cennik" className={styles.navLink}>Cennik</a>
          <a href="/galeria" className={styles.navLink}>Galeria</a>
          <a href="/atrakcje-okolicy" className={styles.navLink}>Atrakcje okolicy</a>
          <a href="/strefa-relaksu" className={styles.navLink}>Strefa Relaksu</a>
          {/* <a href="/pokoje" className={styles.navLink}>Pokoje</a> */}
          <a href="/oferty-specjalne" className={styles.navLink}>Oferty Specjalne</a>
          <a href="/partnerzy" className={styles.navLink}>Partnerzy</a>
          <a href="/kontakt" className={styles.navLink}>Kontakt</a>
          <a href="/blog" className={styles.navLink}>Blog</a>
        </nav>
      </div>


    </nav>
  );
};

export default NavbarAlt;
