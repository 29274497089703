import React, { useEffect, useState } from 'react';

const WeatherWidget = () => {
    const [weatherData, setWeatherData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const apiUrl = 'https://test.itneed.pl/WORDPRESS/wp-json/custom/v1/weather'; // Zaktualizuj URL na swój
    
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setWeatherData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Błąd podczas pobierania danych pogodowych:', error);
                setLoading(false);
            });
    }, []);
    


    if (loading) {
        return null;
    }

    if (!weatherData || !weatherData.main || !weatherData.weather) {
        return <p>Brak informacji o pogodzie.</p>;
    }

    return (
        <div className="flex px-2 items-center self-center text-center">
            <img
                className='w-12 h-12 -my-3 drop-shadow-[0_4px_4px_rgba(0,0,0,0.9)]'
                src={`http://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
                alt={weatherData.weather[0].description}
            />
            <p className='-ml-0 text-darkBlue italic text-[18px] font-bold'>{Math.round(weatherData.main.temp)}°C</p>
        </div>
    );
};

export default WeatherWidget;