// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LoadingProvider } from './contexts/LoadingContext';
import Spinner from './components/Spinner';
import MainLayout from './layouts/MainLayout';
import NoNavbarLayout from './layouts/NoNavbarLayout'; // Jeśli masz taki komponent
import Slider from './components/Slider';
import ReservationBar from './components/ReservationBar';
import RoomSlider from './components/RoomSlider';
import PriceList from './components/PriceList';
// import SliderAnimated from './components/SliderAnimated';
import ReservationPage from './components/ReservationPage';
import AllRooms from './components/AllRooms';
import RoomDetail from './components/RoomDetail';
import { SubpageLoadingProvider } from './contexts/SubpageLoadingContext';
import SubpageSpinner from './components/SubpageSpinner';
import ToBeContinued from './components/ToBeContinued';
import ZigZagSection from './components/ZigZagSection';
import AttractionsInTheArea from './components/AttractionsInTheArea';
import PartnersCustomSlider from './components/PartnersCustomSlider';
import ImageGallery from './components/ImageGallery/ImageGallery';
import ImageGalleryPage from './components/ImageGalleryPage/ImageGalleryPage';
import ImageGalleryCardPage from './components/ImageGalleryCardPage/ImageGalleryCardPage';
import Credentials from './components/Credentials';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
// Inne importy...

function App() {
  return (
    <LoadingProvider>
      <Router>
        <Spinner />
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Slider />
                <ReservationBar />
                <ZigZagSection />
                <RoomSlider />
                <Credentials />
                {/* Tu będziemy dodawać kolejne sekcje */}
              </MainLayout>
            }
          />
          <Route
            path="/pokoje"
            element={
              <MainLayout>
                {/* <SliderAnimated /> */}
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/galeria"
            element={
              <MainLayout>
                <ImageGallery />
              </MainLayout>
            }
          />
          <Route
            path="/galeria/:section_type"
            element={
              <MainLayout>
                <ImageGalleryPage />
              </MainLayout>
            }
          />
          <Route
            path="/galeria/:section_type/:id"
            element={
              <MainLayout>
                <ImageGalleryCardPage />
              </MainLayout>
            }
          />
          <Route
            path="/atrakcje-okolicy"
            element={
              <MainLayout>
                <AttractionsInTheArea />
              </MainLayout>
            }
          />
          <Route
            path="/strefa-relaksu"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/oferty-specjalne"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/partnerzy"
            element={
              <NoNavbarLayout>
                <PartnersCustomSlider />
              </NoNavbarLayout>
            }
          />
            <Route
              path="/blog"
              element={
                <MainLayout>
                  <BlogList />
                </MainLayout>
              }
            />
            <Route
              path="/blog/:slug"
              element={
                <MainLayout>
                  <BlogPost />
                </MainLayout>
              }
            />
          <Route
            path="/kontakt"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/rezerwacja"
            element={
              <MainLayout>
                <ReservationPage />
              </MainLayout>
            }
          />
          <Route
            path="/cennik"
            element={
              <MainLayout>
                <SubpageLoadingProvider>
                    <SubpageSpinner />
                    <PriceList />
                </SubpageLoadingProvider>
              </MainLayout>
            }
          />
          <Route
            path="/wszystkie-pokoje"
            element={
              <MainLayout>
                <AllRooms />
              </MainLayout>
            }
          />
          <Route
            path="/pokoje/:slug"
            element={
              <MainLayout>
                <RoomDetail />
              </MainLayout>
            }
          />
          {/* Inne trasy... */}
        </Routes>
      </Router>
    </LoadingProvider>
  );
}

export default App;
