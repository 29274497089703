import React, { useEffect, useState } from 'react';

function AttractionsInTheArea() {
  const [attractions, setAttractions] = useState([]);

  useEffect(() => {
    fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/atrakcje-okolicy?acf_format=standard')
      .then((response) => response.json())
      .then((data) => {
        setAttractions(data.reverse());
      })
      .catch((error) => console.error('Błąd podczas pobierania atrakcji:', error));
  }, []);

  return (
    <div id="parallax-world-of-ugg">
  {/* Sekcja tytułowa */}
  <section className='my-2'>
    <div className="bg-white py-16 text-center">
      <h1 className="text-5xl font-bold tracking-wide uppercase">Atrakcje okolicy</h1>
    </div>
  </section>

  {/* Iteracja przez atrakcje */}
  {attractions.map((attraction, index) => (
    <div key={attraction.id}>
        {/* Sekcja parallax */}
        <section>
            <div
            className="parallax bg-fixed bg-center bg-cover flex items-center justify-center"
            style={{
                backgroundImage: `url(${attraction.acf.image})`,
                paddingTop: '200px',
                paddingBottom: '200px',
            }}
            >
            <h2 className="text-white text-center drop-shadow-[0_4px_4px_rgba(0,0,0,0.9)] text-6xl font-bold uppercase tracking-widest opacity-90">
                {attraction.acf.title}
            </h2>
            </div>
        </section>

        {/* Sekcja opisu */}
        <section>
            <div className="bg-white px-4 md:px-16 py-16 max-w-4xl mx-auto text-justify">
            <p className="text-lg leading-relaxed">{attraction.acf.opis}</p>
            </div>
        </section>
        </div>
    ))}
    </div>
  );
}

export default AttractionsInTheArea;
