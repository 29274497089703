import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AllRooms = () => {
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/pokoje?acf_format=standard')
            .then(response => response.json())
            .then(data => setRooms(data))
            .catch(error => console.error('Error fetching rooms:', error));
    }, []);

    return (
        <div className="room-grid grid grid-cols-1 md:grid-cols-3 gap-8">
            {rooms.map((room, index) => (
                <div key={index} className="room-card">
                    <Link to={`/pokoje/${room.slug}`}>
                        {/* Wyświetlanie obrazu */}
                        <img src={room.acf.image} alt={room.title.rendered} className="room-image w-full h-auto object-cover" />
                        <h3 className="room-title">{room.title.rendered}</h3>
                        <p className="room-info">Ilość osób: max {room.acf.max_guests}</p>
                        <p className="room-info">Powierzchnia: {room.acf.area} m²</p>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default AllRooms;
