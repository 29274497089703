import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { pl } from 'date-fns/locale';
import { isBefore } from 'date-fns';
import './ReservationBar.css'; // Import stylów

const ReservationBar = () => {
    const [checkInDate, setCheckInDate] = useState(null);
    const [checkOutDate, setCheckOutDate] = useState(null);
    const [numberOfGuests, setNumberOfGuests] = useState(1);
    const [numberOfRooms, setNumberOfRooms] = useState(1);
    const today = new Date(Date.now() - (3600 * 1000 * 24));

    const daysBefore = (date) => {
        if (isBefore(date, today) ) {
            return 'text-gray-400 cursor-not-allowed'; // Wyszarzenie i blokada kliknięcia
        }
        return ''; // Domyślna klasa
    };

    // Funkcja formatująca datę do formatu yyyy-MM-dd
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleReservationClick = (e) => {
        e.preventDefault();
        const formattedCheckIn = formatDate(checkInDate);
        const formattedCheckOut = formatDate(checkOutDate);

        // Tworzenie URL z parametrami
        const url = `/rezerwacja?checkin=${formattedCheckIn}&checkout=${formattedCheckOut}&guests=${numberOfGuests}&rooms=${numberOfRooms}`;
        
        // Przekierowanie na stronę rezerwacji
        window.location.href = url;
    };

    return (
        <>
        <div className="relative reservation-bar bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)] h-auto text-white flex justify-center pb-12">
            <form 
                className="reservation-bar-form w-full max-w-4xl flex flex-col md:flex-row items-center justify-center md:space-y-0 md:space-x-6" 
                onSubmit={handleReservationClick}
            >
                {/* Input daty - Od */}
                <div class="input-wrapper flex flex-row py-4 space-x-4">
                    <div className='flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-6'>
                        <div className="relative flex flex-col items-center">
                            <span className="mb-2">Od:</span>
                            <div className="relative w-full">
                                <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary pointer-events-none" />
                                <DatePicker
                                    selected={checkInDate}
                                    selectsStart
                                    onChange={(date) => setCheckInDate(date)} // Ustawienie daty
                                    className="py-2 px-auto w-32 rounded-md border border-gray-300 bg-white text-black shadow-sm focus:border-primary focus:ring-primary text-center"
                                    dateFormat="dd.MM.yyyy"
                                    placeholderText="Wybierz datę"
                                    dayClassName={daysBefore}
                                    minDate={new Date()}
                                    locale={pl}
                                    withPortal // Pop-up portal
                                    shouldCloseOnSelect={true} // Automatyczne zamykanie po wyborze daty
                                    required
                                />
                            </div>
                        </div>
                        {/* Input daty - Do */}
                        <div className="relative flex flex-col items-center">
                            <span className="mb-2">Do:</span>
                            <div className="relative w-fit">
                                <FaCalendarAlt className="absolute left-3 top-1 transform -translate-y-2 text-primary pointer-events-none" />
                                <DatePicker
                                    selected={checkOutDate}
                                    selectsEnd
                                    onChange={(date) => setCheckOutDate(date)} // Ustawienie daty
                                    className="py-2 px-auto w-32 rounded-md border border-gray-300 bg-white text-black shadow-sm focus:border-primary focus:ring-primary text-center"
                                    dateFormat="dd.MM.yyyy"
                                    placeholderText="Wybierz datę"
                                    minDate={checkInDate || today}
                                    dayClassName={daysBefore}
                                    locale={pl}
                                    withPortal // Pop-up portal
                                    shouldCloseOnSelect={true} // Automatyczne zamykanie po wyborze daty
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    {/* Pozostałe inputy */}
                    <div className='flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-6'>
                        <div className="flex flex-col items-center">
                            <span className="mb-2">Liczba osób:</span>
                            <input
                                type="number"
                                name="guests"
                                min="1"
                                max="10"
                                className="py-2 lg:pl-3 w-16 text-center rounded-md border border-gray-300 text-black bg-lightGray shadow-sm focus:border-primary focus:ring-primary"
                                value={numberOfGuests}
                                onChange={(e) => setNumberOfGuests(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex flex-col items-center">
                            <span className="mb-2">Liczba pokoi:</span>
                            <input
                                type="number"
                                name="rooms"
                                min="1"
                                max="5"
                                className="py-2 lg:pl-3 w-16 text-center rounded-md border border-gray-300 text-black bg-lightGray shadow-sm focus:border-primary focus:ring-primary"
                                value={numberOfRooms}
                                onChange={(e) => setNumberOfRooms(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                </div>

                {/* Przycisk rezerwacji */}
                <div className='zarezerwuj_btn'>
                    <button
                        type="submit"
                        className="bg-lightBlue text-darkBlue py-2 px-6 rounded-md font-semibold hover:bg-accent hover:text-black transition-transform transform hover:scale-105"
                    >
                        ZAREZERWUJ
                    </button>
                </div>
            </form>
            <div className="custom-shape-divider-bottom-1728394499">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
        </div>
        </>
    );
};

export default ReservationBar;
