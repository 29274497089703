import React from 'react'

function ToBeContinued() {
  return (
    <div>
        <h1>Praca w toku</h1>
    </div>
  )
}

export default ToBeContinued