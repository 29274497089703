// src/components/ImageGalleryPage/ImageGalleryPage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './ImageGalleryPage.module.css';

const ImageGalleryPage = () => {
  const { section_type } = useParams();
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCards = async () => {
      try {
        // Pobieranie wszystkich kart typu 'card'
        const response = await axios.get(`https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/card`);
        const allCards = response.data;

        // Filtrujemy karty na podstawie section_type
        const filteredCards = allCards.filter(card => card.acf.sekcja === section_type);
        setCards(filteredCards);
        console.log('Fetched Cards:', filteredCards); // Do debugowania
      } catch (error) {
        console.error('Błąd podczas pobierania kart:', error);
      }
    };

    fetchCards();
  }, [section_type]);

  const handleCardClick = (id) => {
    navigate(`/galeria/${section_type}/${id}`);
  };

  return (
    <div className={styles.sectionContainer}>
      {cards.length > 0 ? (
        cards.map((card) => (
          <div
            key={card.id}
            className={styles.card}
            onClick={() => handleCardClick(card.id)}
          >
            <img
              src={getFirstImageUrl(card)}
              alt={`${card.title.rendered}`} /* Używamy tytułu karty jako alt */
              className={styles.cardImage}
              loading="lazy"
            />
            <h3 className={styles.cardTitle}>{card.title.rendered}</h3>
          </div>
        ))
      ) : (
        <p>Brak dostępnych kart dla tej sekcji.</p>
      )}
    </div>
  );
};

// Funkcja do pobrania pierwszego dostępnego obrazu z galerii
const getFirstImageUrl = (card) => {
  const imageFields = ['image_1_url', 'image_2_url', 'image_3_url', 'image_4_url', 'image_5_url'];
  for (let field of imageFields) {
    if (card.acf[field]) {
      return card.acf[field];
    }
  }
  return 'https://test.itneed.pl/WORDPRESS/wp-content/uploads/2024/04/default-image.jpg';
};

export default ImageGalleryPage;
