// components/BlogList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from './BlogList.module.css';

const BlogList = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios
      .get('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/article?_embed&context=view')
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        console.error('Błąd podczas pobierania artykułów:', error);
      });
  }, []);

  return (
    <div className={styles.blogList}>
      <h1>Wszystkie artykuły</h1>
      <div className={styles.articlesContainer}>
        {articles.map((article) => (
          <div key={article.id} className={styles.articleCard}>
            <div className={styles.thumbnail}>
              {article._embedded &&
                article._embedded['wp:featuredmedia'] &&
                article._embedded['wp:featuredmedia'][0] && (
                  <img
                    src={
                      article._embedded['wp:featuredmedia'][0].source_url
                    }
                    alt={article.title.rendered}
                  />
                )}
            </div>
            <h2>{article.title.rendered}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: article.excerpt.rendered,
              }}
            ></p>
            <Link to={`/blog/${article.slug}`} className={styles.readMore}>
              Czytaj więcej
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
