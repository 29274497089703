import React, { useEffect, useContext, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/effect-coverflow';
import { Autoplay, Thumbs, EffectCoverflow } from 'swiper/modules';
import LoadingContext from '../contexts/LoadingContext';
import './Slider.css';

const Slider = () => {
  const { startLoading, finishLoading } = useContext(LoadingContext);
  const [banners, setBanners] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Sprawdza, czy użytkownik używa urządzenia mobilnego
  useEffect(() => {
    const checkIsMobile = () => {
      const mobileBreakpoint = 768; // Próg dla tabletów i telefonów
      setIsMobile(window.innerWidth <= mobileBreakpoint);
    };

    checkIsMobile(); // Sprawdź przy pierwszym renderowaniu
    window.addEventListener('resize', checkIsMobile); // Aktualizuj przy zmianie rozmiaru okna

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  // Pobieranie danych banerów
  useEffect(() => {
    startLoading('Slider');
    fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/baner?acf_format=standard')
      .then((response) => response.json())
      .then((data) => {
        const sortedBanners = data.sort((a, b) => a.acf.priority - b.acf.priority);
        setBanners(sortedBanners);
        finishLoading('Slider');
      })
      .catch((error) => {
        console.error('Error fetching banners:', error);
        finishLoading('Slider');
      });
  }, [startLoading, finishLoading]);

  return (
    <div className="slidercss block w-auto h-[70vh] object-cover">
      {banners.length > 0 && (
        <>
          {/* Główny slider */}
          <Swiper
            effect={'coverflow'}
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{
              delay: 20000,
              disableOnInteraction: false,
            }}
            watchOverflow={true}
            allowTouchMove={false}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 0,
              slideShadows: false,
              modifier: 0,
            }}
            loop={true}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null  }}
            className="mainSwiper"
            modules={[Autoplay, Thumbs, EffectCoverflow]}
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={index}>
                <a href={banner.acf.url}>
                  <img
                    src={isMobile ? banner.acf.mobile_image : banner.acf.image}
                    alt={banner.title.rendered}
                    className="h-full w-full object-cover"
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Slider z miniaturami */}
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={2}
            slidesPerView={banners.length >= 5 ? 5 : banners.length + 2} // Wyświetl maksymalnie 5 miniaturek
            freeMode={true}
            watchSlidesProgress={true}
            modules={[Thumbs]}
            className="thumbsSwiper"
            autoScrollOffset={5}
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={`thumb-${index}`}>
                <img
                  src={banner.acf.mobile_image || banner.acf.image}
                  alt={`Miniatura ${banner.title.rendered}`}
                  className="cursor-pointer object-cover w-[3.5em] h-[3.5em]"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
};

export default Slider;
