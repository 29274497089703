// src/components/ImageGalleryCardPage/ImageGalleryCardPage.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './ImageGalleryCardPage.module.css';

const ImageGalleryCardPage = () => {
  const { section_type, id } = useParams();
  const [images, setImages] = useState([]);
  const [lightbox, setLightbox] = useState({
    isOpen: false,
    currentIndex: 0,
  });

  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Pobranie pojedynczego wpisu typu 'card' według ID
        const response = await axios.get(`https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/card/${id}`);
        const gallery = response.data.acf;
        console.log('Fetched Gallery:', gallery); // Do debugowania

        // Pobranie wszystkich dostępnych obrazów z pól 'image_1_url' do 'image_50_url'
        const imageFields = Array.from({ length: 50 }, (_, i) => `image_${i + 1}_url`);
        const fetchedImages = imageFields
          .map(field => gallery[field])
          .filter(imageUrl => imageUrl); // Usunięcie pustych pól

        console.log('Fetched Images:', fetchedImages); // Do debugowania
        setImages(fetchedImages);
      } catch (error) {
        console.error('Błąd podczas pobierania obrazów:', error);
      }
    };

    fetchImages();
  }, [id]);

  const openLightbox = (index) => {
    setLightbox({
      isOpen: true,
      currentIndex: index,
    });
  };

  const closeLightbox = () => {
    setLightbox({
      isOpen: false,
      currentIndex: 0,
    });
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setLightbox((prev) => ({
      ...prev,
      currentIndex: (prev.currentIndex - 1 + images.length) % images.length,
    }));
  };

  const showNext = (e) => {
    e.stopPropagation();
    setLightbox((prev) => ({
      ...prev,
      currentIndex: (prev.currentIndex + 1) % images.length,
    }));
  };

  return (
    <div className={`${styles.cardPageContainer}`}>
      <div className={styles.imageGrid}>
        {images.map((imageUrl, index) => (
          <div
            key={index}
            className={styles.imageItem}
            onClick={() => openLightbox(index)}
          >
            <img
              src={imageUrl}
              alt={`Obraz ${index + 1} z sekcji ${section_type}`} /* Używamy opisowego alt */
              className={styles.image}
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {lightbox.isOpen && (
        <div className={styles.lightboxOverlay} onClick={closeLightbox}>
          <div className={styles.lightboxContent} onClick={(e) => e.stopPropagation()}>
            <button className={styles.closeButton} onClick={closeLightbox}>
              &times;
            </button>
            <button className={styles.prevButton} onClick={showPrev}>
              &#10094;
            </button>
            <img
              src={images[lightbox.currentIndex]}
              alt={`Obraz ${lightbox.currentIndex + 1} z sekcji ${section_type}`} /* Używamy opisowego alt */
              className={styles.lightboxImage}
            />
            <button className={styles.nextButton} onClick={showNext}>
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGalleryCardPage;
