import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const ReservationPage = () => {
    const [checkInDate, setCheckInDate] = useState('');
    const [checkOutDate, setCheckOutDate] = useState('');
    const [numberOfGuests, setNumberOfGuests] = useState('');
    const [numberOfRooms, setNumberOfRooms] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');
    const [showMoreText1, setShowMoreText1] = useState(false);
    const [showMoreText2, setShowMoreText2] = useState(false);
    const [status, setStatus] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');

    // Funkcja do pobierania danych z URL
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setCheckInDate(params.get('checkin') || '');
        setCheckOutDate(params.get('checkout') || '');
        setNumberOfGuests(params.get('guests') || '');
        setNumberOfRooms(params.get('rooms') || '');
    }, []);

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
      };

    const toggleText1 = (e) => {
        e.preventDefault();
        setShowMoreText1(!showMoreText1);
    };

    const toggleText2 = (e) => {
        e.preventDefault();
        setShowMoreText2(!showMoreText2);
    };

    const handleSendEmail = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post(`https://test.itneed.pl/WORDPRESS/wp-json/custom/v1/reservation`, {
            checkInDate,
            checkOutDate,
            numberOfGuests,
            numberOfRooms,
            name,
            phone,
            email,
            comments,
            recaptchaToken,
            // Dodamy recaptchaToken później
          });
      
          if (response.data.success) {
            setStatus('E-mail został wysłany pomyślnie!');
                // Opcjonalnie wyczyść pola formularza
                setCheckInDate('');
                setCheckOutDate('');
                setNumberOfGuests('');
                setNumberOfRooms('');
                setName('');
                setPhone('');
                setEmail('');
                setComments('');
                setRecaptchaToken('');
          } else {
            setStatus('Wystąpił błąd podczas wysyłania e-maila.');
          }
        } catch (error) {
          console.error('Błąd podczas wysyłania e-maila:', error);
          setStatus('Wystąpił błąd podczas wysyłania e-maila.');
        }
      };

    return (
        <div className="reservation-page bg-lightGray py-8 px-4 md:px-16 flex justify-center">
            <form className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl space-y-6" onSubmit={handleSendEmail}>
                <h1 className="text-2xl font-bold text-darkBlue text-center">Formularz rezerwacji</h1>

                {status && <p className="text-center text-red-500">{status}</p>}

                {/* Uzupełnione pola formularza */}
                <div className="space-y-4">
                    {/* Wiersz dla dat zameldowania i wymeldowania */}
                    <div className="md:flex md:space-x-4">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-700">Od:</label>
                            <input
                                type="date"
                                name="checkin"
                                value={checkInDate}
                                onChange={(e) => setCheckInDate(e.target.value)}
                                required
                                className="p-2 rounded-md border border-gray-300 text-center shadow-sm focus:border-primary focus:ring-primary"
                            />
                        </div>

                        <div className="flex flex-col w-full">
                            <label className="text-gray-700">Do:</label>
                            <input
                                type="date"
                                name="checkout"
                                value={checkOutDate}
                                onChange={(e) => setCheckOutDate(e.target.value)}
                                required
                                className="p-2 rounded-md border border-gray-300 text-center shadow-sm focus:border-primary focus:ring-primary"
                            />
                        </div>
                    </div>

                    {/* Wiersz dla liczby osób i liczby pokoi */}
                    <div className="md:flex md:space-x-4">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-700">Liczba osób:</label>
                            <input
                                type="number"
                                name="guests"
                                min="1"
                                max="10"
                                value={numberOfGuests}
                                onChange={(e) => setNumberOfGuests(e.target.value)}
                                required
                                className="p-2 rounded-md border border-gray-300 text-center shadow-sm focus:border-primary focus:ring-primary"
                            />
                        </div>

                        <div className="flex flex-col w-full">
                            <label className="text-gray-700">Liczba pokoi:</label>
                            <input
                                type="number"
                                name="rooms"
                                min="1"
                                max="5"
                                value={numberOfRooms}
                                onChange={(e) => setNumberOfRooms(e.target.value)}
                                required
                                className="p-2 rounded-md border border-gray-300 text-center shadow-sm focus:border-primary focus:ring-primary"
                            />
                        </div>
                    </div>

                    {/* Imię i Nazwisko oraz Telefon kontaktowy */}
                    <div className="md:flex md:space-x-4">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-700">Imię i Nazwisko:</label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="p-2 rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                            />
                        </div>

                        <div className="flex flex-col w-full">
                            <label className="text-gray-700">Telefon kontaktowy:</label>
                            <input
                                type="tel"
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                className="p-2 rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                            />
                        </div>
                    </div>

                    {/* E-mail */}
                    <div className="flex flex-col">
                        <label className="text-gray-700">Adres e-mail:</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="p-2 rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                        />
                    </div>

                    {/* Uwagi */}
                    <div className="flex flex-col">
                        <label className="text-gray-700">Uwagi:</label>
                        <textarea
                            name="comments"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            className="p-2 rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                        ></textarea>
                    </div>
                </div>

                {/* Zgody */}
                <div className="space-y-4">
                    <div className="flex items-start">
                        <input type="checkbox" required className="mt-1 mr-2" />
                        <div>
                            <label htmlFor="agree1" className="text-gray-700">
                                {showMoreText1
                                    ? `Zgodnie z wymaganiami ogólnego rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych „RODO” WYRAŻAM ZGODĘ na przetwarzanie moich danych osobowych w celu skorzystania z formularza kontaktowego i nawiązania kontaktu z Administratorem.`
                                    : `Zgodnie z wymaganiami ogólnego rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679...`}
                            </label>
                            <span className="text-primary underline cursor-pointer ml-1" onClick={toggleText1}>
                                {showMoreText1 ? 'Mniej' : 'Więcej'}
                            </span>
                        </div>
                    </div>

                    <div className="flex items-start">
                        <input type="checkbox" required className="mt-1 mr-2" />
                        <div>
                            <label htmlFor="agree2" className="text-gray-700">
                                {showMoreText2
                                    ? `Zapoznałem/am się z klauzulą informacyjną w zakresie danych osobowych zgodnie z wymaganiami RODO i rozumiem, iż podanie moich danych osobowych jest dobrowolne i mam prawo do dostępu do treści swoich danych oraz prawo ich sprostowania, zmiany usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania którego dokonano na podstawie zgody przed jej cofnięciem, prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (dawniej GIODO).`
                                    : `Zapoznałem/am się z klauzulą informacyjną w zakresie danych osobowych zgodnie z wymaganiami RODO...`}
                            </label>
                            <span className="text-primary underline cursor-pointer ml-1" onClick={toggleText2}>
                                {showMoreText2 ? 'Mniej' : 'Więcej'}
                            </span>
                        </div>
                    </div>
                </div>
                {/* RECAPTCHA */}

                <ReCAPTCHA
                    sitekey="6LepiGAqAAAAAHBMgIN--2Ck_1KmXWzFYOzSBH2C"
                    onChange={handleRecaptchaChange}
                    className="flex justify-center"
                />

                {/* Przycisk rezerwacji */}
                <button
                    type="submit"
                    className="w-full bg-primary text-white py-2 rounded-md font-semibold hover:bg-accent hover:text-black transition-transform transform hover:scale-105"
                >
                    ZAREZERWUJ
                </button>
            </form>
        </div>
    );
};

export default ReservationPage;
