import React from 'react'
import NavbarAlt from '../components/NavbarAlt'
import Footer from '../components/Footer'
import styles from './MainLayout.module.css'

function MainLayout({ children }) {
    return (
      <>
        <div className='min-h-[calc(100vh-344px)]'>
          <NavbarAlt />
            <div className={styles.fixedMargin}>
              {children}
            </div>
        </div>
        <Footer />
      </>
    );
  }

export default MainLayout;