// Credentials.js
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios'; // Upewnij się, że masz zainstalowany axios
import styles from './Credentials.module.css';
import { FaStar, FaGoogle } from 'react-icons/fa'; // Importujemy ikony

const Credentials = () => {
  const [reviews, setReviews] = useState([]);
  const [placeData, setPlaceData] = useState(null); // Stan dla danych z WordPressa


  useEffect(() => {
    // Pobieranie opinii z WordPressa
    axios
      .get('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/review?acf_format=standard')
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error('Błąd podczas pobierania opinii:', error);
      });

    // Pobieranie danych z naszego endpointu w WordPressie
    axios
      .get('https://test.itneed.pl/WORDPRESS/wp-json/custom/v1/google-reviews')
      .then((response) => {
        setPlaceData(response.data);
      })
      .catch((error) => {
        console.error('Błąd podczas pobierania danych z WordPressa:', error);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4, // Domyślna liczba slajdów (dla szerokich ekranów)
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderStars = (rating) => {
    const totalStars = 5;
    const stars = [];

    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <FaStar
          key={i}
          className={i <= rating ? styles.starFilled : styles.starEmpty}
        />
      );
    }
    return <div className={styles.stars}>{stars}</div>;
  };

  return (
    <div className={styles.credentials}>
      {placeData && (
        <div className={styles.googleBadge}>
          <FaGoogle className={styles.googleIcon} />
          <div className={styles.badgeContent}>
            <h2 className={styles.placeName}>{placeData.name}</h2>
            <div className={styles.placeRating}>
              <span className={styles.ratingValue}>{placeData.rating}</span>
              <div className={styles.stars}>
                {Array.from({ length: 5 }, (_, i) => (
                  <FaStar
                    key={i}
                    className={
                      i < Math.round(placeData.rating)
                        ? styles.starFilled
                        : styles.starEmpty
                    }
                  />
                ))}
              </div>
              <span className={styles.totalReviews}>
                ({placeData.user_ratings_total} opinii)
              </span>
            </div>
          </div>
        </div>
      )}
      <Slider {...settings}>
        {reviews.map((review) => (
          <div key={review.id} className={styles.reviewSlide}>
            <div className={styles.reviewCard}>
              <div className={styles.userInfo}>
                <img
                  src={review.acf.user_photo}
                  alt={`Zdjęcie użytkownika ${review.acf.user_name}`}
                  className={styles.userPhoto}
                />
                <h3 className={styles.userName}>{review.acf.user_name}</h3>
              </div>
              <p className={styles.commentText}>
                &ldquo;{review.acf.comment_text}&rdquo;
              </p>
              {renderStars(review.acf.rating)}
            </div>
          </div>
        ))}
      </Slider>
      <div className={styles.moreReviews}>
        <a
          href="https://www.google.com/maps/place/Twoja+Firma/reviews"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wszystkie oceny znajdą Państwo klikając w ten link
        </a>
      </div>
    </div>
  );
};

export default Credentials;


