import React from 'react'
// import Footer from '../components/Footer'

function NoNavbarLayout({ children }) {
    return (
      <>
        {children}
      </>
    )
  }

export default NoNavbarLayout;