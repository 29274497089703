// components/Spinner.js
import React, { useContext } from 'react';
import LoadingContext from '../contexts/LoadingContext';
import '../media/preloader.css';

const Spinner = () => {
    
    const { isLoading } = useContext(LoadingContext);

    if (!isLoading) return null;
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
            <div className='svg_preloader flex justify-center items-center h-[100px] w-auto'>
                {/* Your SVG Spinner */}
                <svg height="100%" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0 0 800.000000 317.000000" width="100%" xmlns="http://www.w3.org/2000/svg">
                    <g fill="#000000" transform="translate(0.000000,317.000000) scale(0.100000,-0.100000)">
                    <path d="M701 2403 c15 -2 35 -13 45 -25 17 -18 19 -44 22 -304 l3 -284 39 0 40 0 0 129 0 129 93 4 c74 3 100 9 134 28 100 56 112 208 24 283 -47 40 -91 47 -266 45 -88 0 -148 -3 -134 -5z m299 -42 c94 -66 93 -202 -2 -261 -25 -15 -47 -20 -88 -18 l-55 3 -3 147 -3 147 33 3 c49 6 90 -2 118 -21z"/> {/* "P" */}
                    <path d="M1335 2224 c-76 -39 -110 -107 -109 -219 1 -83 20 -130 68 -173 33 -28 89 -52 126 -52 59 0 160 62 160 98 0 12 -7 10 -27 -10 -16 -14 -38 -31 -50 -37 -32 -16 -105 -13 -132 5 -29 21 -60 89 -61 132 l0 32 146 0 147 0 -7 58 c-10 82 -40 138 -87 162 -47 24 -132 26 -174 4z m128 -34 c28 -22 47 -74 47 -127 l0 -33 -100 0 -100 0 0 35 c0 48 22 97 55 123 35 27 65 28 98 2z"/> {/* "e" */}
                    <path d="M1847 2220 c-34 -19 -40 -19 -60 -5 -14 10 -42 15 -87 14 -36 -1 -53 -4 -39 -6 15 -2 35 -13 45 -25 17 -18 19 -42 22 -215 l4 -194 36 3 37 3 1 180 2 179 26 23 c19 16 40 23 67 23 94 0 109 -34 109 -250 l0 -160 40 0 40 0 0 167 c0 147 -2 172 -20 209 -14 27 -33 48 -54 58 -47 22 -123 20 -169 -4z"/> {/* "n"*/}
                    <path d="M2274 2226 c-43 -19 -64 -53 -64 -102 0 -60 29 -90 136 -140 51 -23 97 -52 103 -63 32 -60 -6 -101 -94 -101 -68 0 -96 14 -126 64 l-19 31 0 -37 c0 -51 25 -73 97 -88 79 -17 156 -2 198 40 24 25 30 39 30 74 0 60 -28 87 -142 141 -103 48 -126 74 -105 120 32 70 169 56 211 -22 l21 -38 -3 44 c-3 64 -35 85 -135 89 -50 1 -88 -3 -108 -12z"/> {/* "s" */}
                    <path d="M2601 2223 c15 -2 35 -13 45 -25 17 -18 19 -40 19 -247 0 -206 -2 -230 -20 -266 -11 -22 -30 -44 -42 -50 -13 -5 -23 -13 -23 -18 0 -12 70 3 102 21 53 31 58 57 58 320 0 284 4 273 -98 271 -37 -1 -55 -4 -41 -6z"/> {/* "j" bez kropki */}
                    <path d="M2657 2383 c-11 -11 -8 -58 5 -71 7 -7 25 -12 40 -12 32 0 47 35 28 69 -10 20 -58 30 -73 14z"/> {/* kropka nad "j"*/}
                    <path d="M2965 2217 c-145 -82 -139 -336 10 -415 40 -21 141 -20 182 1 76 40 121 139 110 242 -6 56 -33 119 -64 147 -56 51 -170 63 -238 25z m144 -22 c68 -34 97 -184 57 -288 -45 -117 -157 -117 -203 1 -38 100 -9 252 55 287 35 18 56 18 91 0z"/> {/* "o" */}
                    <path d="M3518 2218 c-35 -20 -39 -21 -56 -5 -14 13 -36 17 -83 16 -54 -1 -59 -2 -34 -10 52 -15 55 -26 55 -236 l0 -193 40 0 40 0 0 185 0 185 33 20 c46 28 107 26 136 -4 32 -35 40 -82 41 -243 l0 -143 41 0 41 0 -4 173 c-4 192 -12 222 -71 257 -45 28 -130 27 -179 -2z"/> {/* drugie "n" */}
                    <path d="M3953 2229 c-37 -11 -43 -18 -53 -65 -7 -29 -5 -34 11 -34 10 0 25 13 33 29 19 36 65 55 113 48 55 -9 83 -55 83 -137 0 -27 0 -27 -83 -32 -118 -8 -172 -40 -183 -110 -19 -116 121 -186 235 -117 l33 20 22 -20 c12 -12 30 -21 39 -21 15 0 17 17 17 167 0 147 -2 172 -20 209 -14 27 -33 48 -54 58 -37 18 -140 20 -193 5z m187 -288 c0 -65 -2 -71 -31 -95 -35 -29 -67 -33 -108 -11 -32 16 -41 33 -41 79 0 55 57 94 138 95 l42 1 0 -69z"/> {/* "a" */}
                    <path d="M4430 2372 c-25 -9 -45 -45 -50 -92 -5 -42 -7 -45 -37 -48 -47 -5 -44 -32 2 -32 l35 0 0 -170 c0 -105 4 -179 11 -194 24 -52 109 -68 184 -34 40 18 30 25 -29 20 -82 -7 -86 3 -86 210 l0 168 55 0 c42 0 55 3 55 15 0 12 -13 15 -55 15 l-55 0 0 69 c0 72 -4 82 -30 73z"/> {/* "t" */}
                    <path d="M7345 2173 c-291 -216 -400 -279 -533 -309 -148 -33 -295 -1 -502 108 -384 204 -502 253 -670 279 -246 38 -490 -56 -794 -306 -47 -38 -84 -71 -82 -72 2 -2 93 41 203 95 266 131 362 163 515 169 104 5 126 3 207 -21 103 -29 142 -48 325 -153 338 -195 500 -252 680 -240 200 12 386 118 648 366 94 89 143 151 136 171 -2 5 -61 -34 -133 -87z"/> {/* fala */}
                    <path d="M671 1501 c-11 -30 -18 -57 -16 -60 3 -2 19 8 35 24 25 24 43 30 98 36 79 9 402 12 402 3 0 -13 -281 -401 -472 -651 -59 -78 -108 -143 -108 -145 0 -7 617 -2 640 5 47 15 120 50 120 59 0 5 -17 6 -37 2 -94 -18 -543 -4 -543 17 0 15 361 508 507 691 29 37 53 68 53 70 0 2 -148 3 -329 3 l-330 1 -20 -55z"/> {/* "Z" */}
                    <path d="M1570 1563 c-52 -2 -105 -5 -118 -8 -28 -7 -19 -25 12 -25 13 0 34 -9 47 -19 l24 -19 0 -394 0 -393 225 0 c208 0 229 2 285 23 203 76 302 237 293 478 -3 93 -7 108 -38 170 -25 49 -49 79 -84 105 -105 80 -225 95 -646 82z m371 -43 c78 -15 172 -64 214 -112 54 -64 76 -136 77 -248 1 -168 -51 -284 -159 -357 -79 -52 -145 -67 -276 -61 -78 4 -104 9 -125 25 l-27 20 -3 372 -2 371 124 0 c68 0 148 -5 177 -10z"/> {/* "D" */}
                    <path d="M2473 1560 c-40 -3 -75 -9 -78 -12 -3 -3 15 -11 40 -17 35 -9 48 -18 57 -39 12 -31 16 -665 4 -749 l-8 -51 57 17 57 18 -1 190 -2 190 67 7 c52 5 69 4 75 -6 30 -54 187 -257 247 -320 72 -76 74 -76 143 -88 39 -7 72 -10 75 -7 2 2 -12 15 -31 28 -52 35 -335 398 -335 429 0 4 11 12 25 18 42 19 117 95 136 137 57 123 -17 226 -182 254 -70 12 -227 12 -346 1z m304 -46 c100 -27 152 -103 137 -200 -15 -101 -91 -155 -230 -162 l-84 -5 0 197 0 196 63 -6 c34 -3 86 -12 114 -20z"/> {/* "R" */}
                    <path d="M3585 1574 c-141 -33 -201 -62 -276 -132 -75 -69 -116 -149 -129 -253 -19 -157 31 -314 131 -403 71 -65 138 -90 256 -94 122 -5 210 17 314 78 167 98 227 221 217 446 -6 131 -32 197 -106 266 -71 66 -141 90 -277 94 -60 2 -119 1 -130 -2z m219 -69 c70 -33 138 -108 167 -183 41 -108 36 -282 -11 -389 -27 -62 -102 -137 -163 -164 -74 -32 -221 -33 -289 -2 -135 62 -206 173 -223 348 -20 205 81 371 252 415 69 17 205 5 267 -25z"/> {/* "O" */}
                    <path d="M4410 1570 c-8 -6 -35 -10 -60 -10 -72 0 -150 -12 -144 -21 3 -5 18 -9 33 -9 16 0 39 -9 52 -19 l24 -19 3 -354 c4 -492 -8 -562 -115 -657 -41 -37 -50 -50 -39 -54 22 -9 132 77 178 138 72 96 72 90 78 575 3 239 5 436 5 437 0 1 -7 -2 -15 -7z"/> {/* "J" */}
                    <path d="M4924 1561 c-230 -62 -354 -214 -354 -433 0 -188 81 -333 224 -403 50 -24 76 -30 154 -33 150 -7 254 25 379 114 117 83 165 195 166 379 0 89 -4 121 -22 166 -38 100 -99 162 -199 200 -82 32 -251 36 -348 10z m229 -41 c88 -27 162 -94 203 -182 27 -60 29 -70 29 -193 -1 -112 -4 -138 -24 -188 -35 -89 -77 -137 -155 -177 -62 -31 -76 -35 -155 -35 -100 0 -149 13 -214 56 -86 57 -138 154 -158 294 -18 134 14 255 91 339 90 98 237 131 383 86z"/> {/** "O" */}
                    <path d="M5563 1563 c-46 -2 -83 -8 -83 -13 0 -6 9 -10 20 -10 41 0 70 -24 96 -78 36 -77 194 -503 240 -647 20 -66 39 -122 41 -124 8 -10 41 33 47 60 6 34 67 176 144 337 36 77 51 100 59 90 16 -20 170 -387 184 -438 7 -25 14 -47 16 -49 8 -11 40 50 64 124 50 152 267 632 320 706 11 16 20 32 18 34 -2 2 -25 6 -50 10 l-47 7 -29 -94 c-33 -104 -233 -598 -242 -598 -6 0 -182 409 -181 423 0 15 93 173 126 213 l33 42 -41 6 c-22 4 -43 4 -47 1 -3 -4 -25 -50 -47 -103 -23 -53 -46 -98 -51 -99 -5 -2 -27 41 -50 94 l-41 98 -86 3 c-55 2 -86 -1 -86 -8 0 -5 9 -10 20 -10 12 0 32 -11 46 -25 25 -25 134 -237 134 -261 0 -14 -156 -354 -169 -367 -10 -11 -240 646 -230 658 16 17 9 25 -18 23 -16 -1 -65 -4 -110 -5z"/> {/* "W" */}
                    <path d="M6847 1560 c-52 -10 -54 -16 -10 -25 21 -3 49 -16 61 -28 28 -26 158 -232 227 -360 l48 -89 -7 -183 -7 -183 54 15 54 15 -5 184 -5 183 65 113 c104 181 198 306 253 336 28 15 18 20 -44 23 l-54 3 -115 -205 c-63 -112 -117 -206 -119 -209 -3 -3 -9 2 -14 10 -5 8 -62 104 -126 213 l-117 197 -51 -1 c-27 -1 -67 -5 -88 -9z"/> {/* "Y" */}
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default Spinner;
